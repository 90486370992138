<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M2475 4792 c-108 -12 -188 -50 -1020 -477 -99 -51 -281 -144 -404
                -206 -162 -83 -235 -126 -266 -157 -62 -61 -83 -135 -58 -209 26 -76 78 -114
                328 -237 886 -439 1217 -600 1265 -616 95 -32 228 -40 326 -21 71 14 134 40
                297 125 78 40 273 140 432 221 160 81 414 211 565 288 151 77 294 154 316 171
                116 87 132 216 38 314 -33 34 -82 65 -175 110 -46 22 -349 172 -674 332 -662
                328 -654 324 -734 345 -58 15 -178 24 -236 17z m188 -247 c40 -13 243 -112
                1251 -610 l189 -93 -84 -42 c-46 -24 -183 -93 -304 -155 -121 -62 -414 -210
                -651 -330 l-430 -218 -90 -3 c-67 -2 -101 2 -139 15 -27 10 -269 127 -537 259
                -267 132 -577 285 -687 339 -111 54 -201 101 -201 105 0 4 123 68 273 144 149
                75 376 190 502 254 480 245 656 332 682 339 52 15 178 12 226 -4z"
            />

            <path d="M740 3370 c-75 -15 -122 -49 -157 -111 -17 -32 -18 -81 -20 -965 -2
                -1013 -4 -974 54 -1073 63 -108 116 -144 508 -346 94 -49 175 -91 180 -95 6
                -4 82 -44 170 -90 88 -46 183 -95 210 -110 237 -128 466 -239 507 -245 113
                -17 221 48 246 148 9 34 12 292 12 961 l0 914 -22 54 c-35 85 -91 161 -158
                210 -37 27 -61 41 -230 130 -122 65 -237 125 -370 196 -277 146 -438 231 -550
                289 -69 36 -143 76 -165 88 -71 40 -153 57 -215 45z m168 -281 c53 -28 149
                -78 212 -111 63 -33 147 -77 185 -98 39 -20 129 -68 200 -105 72 -37 171 -89
                220 -115 50 -26 153 -80 230 -120 165 -85 197 -108 233 -162 l27 -41 0 -879
                c0 -483 -2 -882 -5 -887 -3 -4 -59 21 -125 56 -66 35 -194 103 -285 150 -91
                47 -196 103 -235 123 -38 20 -146 77 -240 125 -93 48 -188 98 -210 110 -22 12
                -78 42 -124 65 -46 24 -102 59 -123 78 -78 70 -72 -6 -73 980 0 485 3 882 8
                882 4 0 51 -23 105 -51z"
            />

            <path d="M4290 3363 c-54 -14 -161 -67 -595 -298 -126 -67 -279 -148 -340
                -180 -60 -32 -159 -85 -220 -117 -60 -32 -139 -73 -175 -91 -91 -45 -191 -145
                -232 -233 l-33 -69 0 -945 0 -945 22 -42 c47 -86 138 -126 242 -105 32 7 78
                23 102 35 24 13 107 56 184 97 77 40 163 85 190 100 28 15 95 51 150 80 200
                105 383 202 525 277 41 22 117 62 169 89 120 62 205 143 252 240 l34 69 3 930
                c2 686 0 940 -9 970 -16 56 -43 91 -92 119 -53 31 -109 37 -177 19z m45 -1115
                l0 -883 -23 -32 c-33 -46 -92 -94 -159 -128 -32 -16 -114 -59 -183 -96 -69
                -36 -179 -94 -245 -129 -66 -34 -142 -75 -170 -90 -27 -15 -104 -56 -170 -90
                -172 -90 -279 -146 -368 -194 -54 -28 -80 -37 -85 -29 -4 6 -7 405 -6 885 l0
                875 24 34 c39 57 79 85 235 168 82 43 186 98 230 121 104 55 235 125 360 190
                55 29 132 70 170 90 39 21 120 64 180 95 61 32 130 69 154 82 24 12 46 21 50
                19 4 -3 7 -402 6 -888z"
            />
        </g>
    </svg>
</template>
